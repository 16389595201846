import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js'
import { PrimaryLink as PrimaryLinkBase } from 'components/misc/Links.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import { ReactComponent as LocationIcon } from 'feather-icons/dist/icons/map-pin.svg'
import { ReactComponent as TimeIcon } from 'feather-icons/dist/icons/clock.svg'
import { ReactComponent as TrendingIcon } from 'feather-icons/dist/icons/trending-up.svg'
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-icon.svg'
import secondImage from '../../images/ours/2.jpg'
import thirdImage from '../../images/ours/3.jpg'
import fourthImage from '../../images/ours/4.jpg'

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const ThreeColumn = tw.div`flex flex-wrap`
const Column = tw.div``
const HeadingColumn = tw(Column)`w-full xl:w-1/3`
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0`

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`
// const CardImage = styled.div((imageSrc) => [
//   `background-image: "${imageSrc}";`,
//   tw`h-80 bg-cover bg-center rounded`,
// ])

const CardText = tw.div`mt-4`

const CardHeader = tw.div`flex justify-between items-center`
const CardType = tw.div`font-bold text-lg`
const CardPrice = tw.div`font-semibold text-sm text-gray-600`
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`

const CardTitle = tw.h5`text-xl mt-4 font-bold`

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`

export default () => {
  const cards = [
    {
      imageSrc: secondImage,
      type: 'Client Server',
      // pricePerDay: "$99",
      title:
        'Host static and dynamic websites in the cloud with Click to Deploy or customized solutions.',
      // trendingText: "Trending",
      // durationText: "7 Days Tour",
      // locationText: "Africa"
    },
    {
      imageSrc: thirdImage,
      type: 'E-commerce',
      // pricePerDay: "$169",
      title:
        'With our solutions for the optimization of your store and each of your websites, we look forward to supporting you on the road to success. We can help you attract more visitors and increase your conversion by providing fresh text content in all major languages, accurate product descriptions, categorizations and tags, and the generation of market information.',
      // trendingText: "Trending",
      // durationText: "15 Days Tour",
      // locationText: "Australia"
    },
    {
      imageSrc: fourthImage,
      type: 'System design and Development',
      // pricePerDay: "$169",
      title:
        'From small to big businesses, every business today now are finding a great need for a website to put its product or services on the online platform. These businesses know that an attractive website will help you to attract more customers to your offering and increase the brand awareness of your business',
      // trendingText: "Trending",
      // durationText: "15 Days Tour",
      // locationText: "Australia"
    },
  ]
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>SERVICES</HeadingTitle>
              <HeadingDescription>
                Soft Sky consultancy offers a comprehensive range of services
                and solutions in the areas of information technology and
                management, for a number of business and industry sectors. The
                services and solutions that the company provides are conceived,
                developed and implemented in accordance with each client's
                specific requirements.
              </HeadingDescription>
              <PrimaryLink>
                {/* View All Tours <ArrowRightIcon /> */}
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                {/* <CardImage imageSrc={card.imageSrc} /> */}
                <img src={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardType style={{ color: '#2560a8' }}>
                      {card.type}
                    </CardType>
                    {/* <CardPrice>
                      <CardPriceAmount>{card.pricePerDay}</CardPriceAmount> per day
                    </CardPrice> */}
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  {/* <CardMeta>
                    <CardMetaFeature>
                      <TrendingIcon /> {card.trendingText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature>
                  </CardMeta> */}
                  {/* <CardAction>Book Now</CardAction> */}
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  )
}
