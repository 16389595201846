import React, { useState } from 'react'
import tw from 'twin.macro'
import { ReactComponent as EmailNewsletterIconBase } from '../../images/email-newsletter-icon.svg'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import { SectionHeading } from 'components/misc/Headings.js'
import { PrimaryButton } from 'components/misc/Buttons.js'
import axios from 'axios'

const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`

const EmailNewsletterIcon = tw(
  EmailNewsletterIconBase
)`w-16 h-16 text-green-500`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`
const Button = tw(
  PrimaryButton
)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`

export default () => {
  const [name, setName] = useState('')
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [descriptionForm, setDescriptionForm] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      name,
      email,
      phone,
      description: descriptionForm,
    }
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_BACKEND}/sendEmail`, payload)
      .then((res) => {
        setSuccess('Request Recevied Our Executive will contact you shortly')
        setEmail('')
        setDescriptionForm('')
        setName('')
        setPhone('')
        setLoading(false)
        setTimeout(() => {
          setSuccess('')
        }, 6000)
        // document.body.scrollTop = document.documentElement.scrollTop = 0
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 2000)
      })
      .catch((error) => {
        console.log(error)
        setError('Something Happend Please Try again later')
        setTimeout(() => {
          setError('')
        }, 6000)
        setLoading(false)
      })
  }
  return (
    <Container>
      <Content>
      <Heading>{"Contact Us"}</Heading>
        <Row>
          {error && (
            <p
              className='error'
              style={{
                background: '#fff0f0',
                color: '#ff0000',
                padding: '10px',
              }}
            >
              {error}
            </p>
          )}

          {success && (
            <p
              className='success'
              style={{
                background: '#e7f7e2',
                color: '#0cf327',
                padding: '10px',
              }}
            >
              {success}
            </p>
          )}
          <br />
          <form onSubmit={(e) => handleSubmit(e)}>
            <Input
              type='text'
              onChange={(e) => setName(e.target.value)}
              name='name'
              placeholder='Your Name'
              value={name}
            />{' '}
            <br />
            <br />
            <Input
              type='email'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Your Email Address'
              value={email}
            />{' '}
            <br />
            <br />
            <Input
              type='Number'
              name='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder='Your Mobile Number'
            />
            <br />
            <br />
            <Input
              type='text'
              onChange={(e) => setDescriptionForm(e.target.value)}
              value={descriptionForm}
              name='description'
              placeholder='description'
            />
            <br />
            <br />
            <button
              style={{
                backgroundColor: '#4CAF50',
                border: 'none',
                color: 'white',
                padding: '15px 32px',
                textAlign: 'center',
                textDecoration: 'none',
                display: 'inline-block',
                fontSize: '16px',
              }}
              type='submit'
              disabled={
                !name || !email || !phone || !descriptionForm || loading
              }
            >
              Contact us
            </button>
          </form>
        </Row>
      </Content>
    </Container>
  )
}
