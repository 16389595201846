import React from 'react'
import GlobalStyles from 'styles/GlobalStyles'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HotelTravelLandingPage from 'demos/HotelTravelLandingPage'

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path='/' element={<HotelTravelLandingPage />} />
        </Routes>
      </Router>
    </>
  )
}
